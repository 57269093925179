import { Controller } from "@hotwired/stimulus"
import uFuzzy from "@leeoniya/ufuzzy"
import { useDebounce } from "stimulus-use"

export default class extends Controller {

  static debounces = ['filter']
  static targets = ['buttonFilter', 'buttonHide', 'search', 'line']

  connect() {
    useDebounce(this)
    // initialize the fuzzy matcher, we allow 1-letter errors (see ufuzzy docs)
    this.uf = new uFuzzy({intraMode: 1, intraDel: 1, intraSub: 1})
  }

  toggleTag(event) {
    let button = event.currentTarget
    button.classList.toggle('active')
    this.filter()
  }

  changeSearch() {
    this.filter()
  }

  reset() {
    this.buttonFilterTargets.forEach(el => { el.classList.remove('active') })
    this.buttonHideTargets.forEach(el => { el.classList.remove('active') })
    this.searchTarget.value = ''
    this.filter()
  }

  filter() {
    // populates tags to be filtered
    let selectedTags = []
    this.buttonFilterTargets.forEach(el => {
      if (el.classList.contains('active')) { selectedTags.push(el.dataset.tag) }
    })
    // populate tags to be hidden
    let hideTags = []
    this.buttonHideTargets.forEach(el => {
      if (el.classList.contains('active')) { hideTags.push(el.dataset.tag) }
    })
    // process
    if (selectedTags.length) {
      this.lineTargets.forEach(el => {
        el.classList.add('d-none')
        selectedTags.forEach(tag => {
          if (el.dataset.tags.includes(tag)) { el.classList.remove('d-none') }
        })
      })
    }
    if (hideTags.length) {
      this.lineTargets.forEach(el => {
        hideTags.forEach(tag => {
          if (el.dataset.tags.includes(tag)) { el.classList.add('d-none') }
        })
      })
    }
    if (!selectedTags.length && !hideTags.length) {
      this.lineTargets.forEach(el => { el.classList.remove('d-none') })
    }
    // filter by text field
    if (this.searchTarget.value.length) {
      // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
      let needle = this.searchTarget.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()
      this.lineTargets.forEach(el => {
        // only filter visible lines
        if (!el.classList.contains('d-none')) {
          let nodes = el.querySelectorAll('.searchable')
          let list = [].slice.call(nodes)
          let txt = list.map(e => e.innerText).join(' ')
          let haystack = [txt.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().replace(/\s+/g, ' ').trim()]
          // console.log(`searching ${needle} in ${haystack[0]}`)
          // true param allows out-of-order search terms
          if (this.uf.search(haystack, needle, true)[0].length) {
            // nothing, keep it shown
          } else {
            el.classList.add('d-none')
          }
        }
      })
    }
  }

}
