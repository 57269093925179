import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { frameId: String }

  connect() {
    document.getElementById(this.frameIdValue)?.reload()
    this.element.remove()
  }

}
